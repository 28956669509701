<template>  
    <div v-if="loaded">
        <h4 class="mb-3">Configuration</h4>

        <p style="text-align: justify;">
            Vous pouvez modifier les paramètres de fonctionnement de l'application dans cette section.
            Une fois que la configuration est sauvegardée/modifiée, elle est synchronisée sur les différents composants de FormaCloud.
        </p>

        <div class="alert alert-warning">
            <i class="bi bi-info-circle-fill me-2"></i>Modifications non disponibles pour le moment.
        </div>

        <h6 class="mb-3">SSO paramètres</h6>
        <div class="list-group list-group-action shadow shadow-sm mb-3" v-if="loaded">
            <div class="list-group-item">
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Url de redirection</label>
                    <input type="text" name="" id="" class="form-control form-control-sm" v-model="configsApi.auth.rootUri" :disabled="disabled">
                </div>
            </div>
            <div class="list-group-item">
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Authority</label>
                    <input type="text" name="" id="" class="form-control form-control-sm" v-model="configsApi.auth.azure.authority" :disabled="disabled">
                </div>
            </div>
            <div class="list-group-item">
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Client ID</label>
                    <input type="text" name="" id="" class="form-control form-control-sm" v-model="configsApi.auth.azure.clientId" :disabled="disabled">
                </div>
            </div>
            <div class="list-group-item">
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Client Secret</label>
                    <input type="password" name="" id="" class="form-control form-control-sm" v-model="configsApi.auth.azure.clientSecret" :disabled="disabled">
                </div>
            </div>
        </div>

        <h6 class="mb-3">Configuration AWS</h6>
        <div class="list-group list-group-action shadow shadow-sm mb-3" v-if="loaded">
            <div class="list-group-item">
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Region</label>
                    <input type="text" name="" id="" class="form-control form-control-sm" v-model="configsApi.aws.region" :disabled="disabled">
                </div>
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Access Key</label>
                    <input type="text" name="" id="" class="form-control form-control-sm" v-model="configsApi.aws.access_key" :disabled="disabled">
                </div>
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Secret Key</label>
                    <input type="password" name="" id="" class="form-control form-control-sm" v-model="configsApi.aws.secret_key" :disabled="disabled">
                </div>
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Instances type autorisées <small class="text-muted"><em>(séparés par des virgules)</em></small></label>
                    <input type="text" name="" id="" class="form-control form-control-sm" v-model="allowedInstanceTypesFormat" :disabled="disabled">
                </div>
            </div>
        </div>
        
        <h6 class="mb-3">Facturation</h6>
        <div class="list-group list-group-action shadow shadow-sm mb-3" v-if="loaded">
            <div class="list-group-item">
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Budget Max</label>
                    <input type="number" name="" id="" class="form-control form-control-sm" v-model="configsApi.aws.maxBudget" :disabled="disabled">
                </div>
            </div>
        </div>

        <h6 class="mb-3">API & Workers</h6>
        <div class="list-group list-group-action shadow shadow-sm mb-3" v-if="loaded">
            <div class="list-group-item">
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Workers count <small class="text-muted"><em>(0 for cores count, other to overwrite. Reloaded after full restart)</em></small></label>
                    <input type="number" name="" id="" class="form-control form-control-sm" v-model="configsApi.server.fork" :disabled="disabled">
                </div>
            </div>
        </div>

        <h6 class="mb-3">Purge des infrastructures et des comptes</h6>
        <div class="list-group list-group-action shadow shadow-sm mb-3" v-if="loaded">
            <div class="list-group-item">
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Uniquement les infrastructures</label>
                    <br>
                    <button type="button" class="btn btn-sm btn-danger" style="width:100px;" :disabled="!deletion_infra" @click="startPurge(false)">Purger</button>
                    <div class="ms-2 form-check form-check-inline">
                        <input class=" form-check-input" type="checkbox" id="inlineCheckbox1" v-model="deletion_infra">
                        <label class="form-check-label" for="inlineCheckbox1">En cochant cette case vous autorisez le système à supprimer.</label>
                    </div>
                </div>
                <div class="mb-2">
                    <label for="" class="form-label fw-light">Les infrastructures et les comptes utilisateurs (sauf admin)</label>
                    <br>
                    <button type="button" class="btn btn-sm btn-danger" style="width:100px;" :disabled="!deletion_infra_accounts" @click="startPurge(true)">Purger</button>
                    <div class="ms-2 form-check form-check-inline">
                        <input class=" form-check-input" type="checkbox" id="inlineCheckbox2" v-model="deletion_infra_accounts">
                        <label class="form-check-label" for="inlineCheckbox2">En cochant cette case vous autorisez le système à supprimer.</label>
                    </div>
                </div>
            </div>
        </div>
        
        <button class="btn btn-light w-100" :disabled="disabled" @click="saveConfig">
            <i class="bi bi-save me-2"></i>Enregistrer
        </button>


        
    </div>
    <div v-else>
        <pre><span class="spinner spinner-border spinner-border-sm me-2"></span>{{ (loadMessage !== "") ? loadMessage : "Chargement..."}}</pre>
    </div>
</template>

<script setup>

    import {onMounted, ref, toRefs, defineEmits, defineProps} from "vue";
    import axios from "axios";

    let props = defineProps(["userInfo"]);

    // Manage loading state
    let loaded = ref(false);
    let loadMessage = ref("");
    let allowedInstanceTypesFormat = ref("");
    let disabled = ref( (props.userInfo.authLevel > 0) ? true : false );

    // Local vars
    let configsApi = ref(null);
    let deletion_infra = ref(false);
    let deletion_infra_accounts = ref(false);

    onMounted(() => {
        load();
    });

    function load(){
        axios.get("/api/configs")
            .then( answer => {
                configsApi.value = answer.data;
                allowedInstanceTypesFormat.value = configsApi.value.aws.allowedInstanceTypes.join(",");
                loaded.value = true;
            })
    }

    function saveConfig(){
        disabled.value = true;
        configsApi.value.aws.allowedInstanceTypes = allowedInstanceTypesFormat.value.split(",");
        axios.post("/api/configs", {config: configsApi.value})
            .then( answer => {
                disabled.value = false;
            })
    }

    function startPurge(withAccounts){
        loaded.value = false;
        loadMessage.value = "PURGE :: Récupération des infrastructures..."
        axios.get("/api/infras?limit=100")
            .then( answer => {
                loadMessage.value += "\n   PURGE :: Infras à supprimer : "+answer.data.length;

                if(answer.data.length === 0){
                    loadMessage.value += "\n   PURGE :: Supression terminée, aucune infras à supprimer";
                }
                else{
                    for(let infra of answer.data){
                        loadMessage.value += `\n   PURGE :: => Infra nommée [${infra.name}] en cours de supression `;
                        axios.delete(`/api/infras/${infra._id}`);
                    }
                    loadMessage.value += "\n   PURGE :: Supression terminée, celà peux prendre du temps";
                }

                if(withAccounts){
                    let accounts = [];
                    let groups = [];
                    loadMessage.value += "\n   PURGE :: Récupération des comptes utilisateurs";
                    loadMessage.value += "\n   PURGE :: Récupération des groupes";
                    axios.get("/api/users?limit=100")
                        .then( answer => {
                            accounts = answer.data;
                            return axios.get("/api/groups?limit=100");
                        })
                        .then( answer => {
                            groups = answer.data;
                            loadMessage.value += "\n   PURGE :: Nombre de comptes à supprimer : " + accounts.length;
                            if(accounts.length === 0) {
                                loadMessage.value += "\n   PURGE :: Aucun comptes à supprimer";
                            }
                            else{
                                for(let account of accounts){
                                    if(account.authLevel === 0){
                                        loadMessage.value += `\n   PURGE :: => Compte ${account.email} ignoré car protégé`; 
                                    }
                                    else{
                                        axios.delete(`/api/users/${account._id}`);
                                        loadMessage.value += `\n   PURGE :: => Compte ${account.email} supprimé `;
                                    }
                                }
                                loadMessage.value += `\n   PURGE :: => Purge des comptes terminé `;
                            }

                            if(groups.length === 0){
                                loadMessage.value += "\n   PURGE :: Aucun groupes à supprimer";
                            }
                            else{
                                for(let group of groups){
                                    if(group.name === "Default group"){
                                        loadMessage.value += `\n   PURGE :: => Groupe ${group} ignoré car protégé`;
                                    } 
                                    else {
                                        axios.delete(`/api/groups/${group._id}`);
                                        loadMessage.value += `\n   PURGE :: => Groupe ${group.name} supprimé `;
                                    }
                                }
                                loadMessage.value += `\n   PURGE :: => Purge des groupes terminé `;
                            }
                        })
                }

                setTimeout( () => loaded.value = true, 5000);

            })
    }


</script>